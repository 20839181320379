.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-tabs-nav .ant-tabs-tab:last-child {
  padding: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-tabs-extra-content {
  line-height: 50px;
  position: absolute;
  right: 15px;
}

@media (max-width: 767px) {
  .ant-col {
    width: 100%;
  }
}

.TranslateBackground:nth-child(2) {
  background-color: aquamarine;
}

.InitialBackground:nth-child(2) {
  background-color: #fff;
}
